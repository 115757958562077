import OnDemandThankYouPage from "components/thank-you/on-demand-video/OnDemandThankYouPage";
import React from "react";

export default function ThankYouPageRoot() {
  return (
    <OnDemandThankYouPage
      videoId="1uahrhjndf"
      title="Introduction to Next-Generation Cloud Video Security"
      description="Learn how some of the biggest companies in the world use cloud-managed solutions to eliminate inefficiencies and improve operations with simple, secure, and scalable system architecture."
    />
  );
}
